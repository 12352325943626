// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import hljs from 'highlight.js';
import lightGallery from 'lightgallery';
import RailsUjs from '@rails/ujs';
import Turbolinks from 'turbolinks';
import 'bootstrap';
import 'channels';

// start rails ujs
RailsUjs.start();
// start turbolinks
Turbolinks.start();

// require('@rails/ujs').start();
// require('turbolinks').start();
// require('@rails/activestorage').start();
// require('channels');

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

class App {
  start() {
    this.setHighlight();
    this.initGallery();
  }

  setHighlight() {
    document.querySelectorAll('pre code').forEach((el) => hljs.highlightElement(el));
  }

  initGallery() {
    const lgContainer = document.getElementById('inline-gallery-container');
    if (!lgContainer) return;
    const inlineGallery = lightGallery(lgContainer, {
      container: lgContainer
    });
    inlineGallery.openGallery();
  }
}

document.addEventListener('turbolinks:load', () => {
  const app = new App();
  app.start();
});
